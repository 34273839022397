import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Slider from 'react-slick';

const SliderOne = styled(Slider)`
  height: 40rem;
  width: 100%;
  @media (max-width: ${props => props.theme.responsive.small}) {
    height: 24rem;
  }
  .slick-list,
  .slick-track,
  .slick-slide,
  .play-button-mob {
    height: 40rem;
    display: block;
    @media (max-width: ${props => props.theme.responsive.small}) {
      height: 24rem;
    }
  }
  a {
    display: flex !important;
  }
  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 40rem;
    @media (max-width: ${props => props.theme.responsive.small}) {
      height: 27.5rem;
    }
  }
`;

class SliderImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const settings = {
      dots: false,
      arrows: false,
      pauseOnHover: false,
      fade: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    const { properties, slider1 } = this.props;
    return (
      <SliderOne {...settings} {...slider1}>
        {properties.map(({ node: slick }) => (
          <Link key={slick.id} to={`/property/${slick.seoContent.slug}/`}>
            <Img
              fluid={slick.seoContent.featuredImage.fluid}
              alt={slick.seoContent.featuredImage.title}
            />
          </Link>
        ))}
      </SliderOne>
    );
  }
}

export default SliderImage;
