import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

// Components
import { SelectedImages, PropertyLinks } from '.';

// Icons
import { ScrollDownIcon } from '../../../../../assets/images/icons';

const Scroll = styled.a`
  position: absolute;
  z-index: 5;
  bottom: 0;
  padding-bottom: 1rem;
  color: ${props => props.theme.colors.global.base.light};
  left: 50%;
  transform: translateX(-50%);
  font-family: ${props => props.theme.fonts.header};
  opacity: 0.5;
  font-size: 1.125em;
  display: flex;
  align-items: center;
  svg {
    margin-right: 1rem;
    g {
      fill: ${props => props.theme.colors.global.base.light};
    }
  }
  &:hover {
    opacity: 1;
    color: ${props => props.theme.colors.global.base.light};
  }
`;

class HoverImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      selectedID: ''
    };
  }

  hoverOn = selectedID => {
    this.setState({
      hover: true,
      selectedID
    });
  };

  hoverOff = () => {
    this.setState({
      hover: false
    });
  };

  render() {
    const { hover, selectedID } = this.state;
    return (
      <StaticQuery
        query={graphql`
          query {
            allContentfulProperties(sort: { fields: [menuOrder], order: ASC }) {
              edges {
                node {
                  id
                  title
                  seoContent {
                    menuTitle
                    slug
                    featuredImage {
                      fluid(maxWidth: 2000) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                      }
                    }
                  }
                  information {
                    tagline
                  }
                  menuOrder
                }
              }
            }
          }
        `}
        render={data => (
          <React.Fragment>
            <SelectedImages
              properties={data.allContentfulProperties.edges}
              selectedID={selectedID}
              hover={hover}
            />
            <PropertyLinks
              properties={data.allContentfulProperties.edges}
              selectedID={selectedID}
              hover={hover}
              hoverOn={this.hoverOn}
              hoverOff={this.hoverOff}
            />
            <Scroll href="javascript:;">
              {ScrollDownIcon}
              Scroll to Continue
            </Scroll>
          </React.Fragment>
        )}
      />
    );
  }
}
export default HoverImages;
