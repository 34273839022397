import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  > div {
    &.showProperty {
      .gatsby-image-wrapper {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
      }
    }
    .gatsby-image-wrapper {
      position: absolute !important;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      object-fit: cover;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      height: 75vh !important;
      max-height: 42em;
      min-height: 45em;
      @media (max-width: ${props => props.theme.responsive.medium}) {
        min-height: 32em;
      }
    }
  }
`;

const SelectedImages = ({ properties, selectedID, hover }) => (
  <Wrapper>
    {properties.map(({ node: property }) => {
      const isActive = property.id === selectedID;
      const showProperty = isActive && hover === true ? 'showProperty' : '';
      return (
        <div key={property.id} className={showProperty}>
          <Img
            fluid={property.seoContent.featuredImage.fluid}
            alt={property.seoContent.featuredImage.title}
          />
        </div>
      );
    })}
  </Wrapper>
);

export default SelectedImages;
