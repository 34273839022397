import styled from 'styled-components';

const HeroWrapper = styled.div`
  display: none;
  height: 40rem;
  position: relative;
  width: 100%;
  @media (max-width: ${props => props.theme.responsive.small}) {
    display: block;
    height: 24rem;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    z-index: 1;
    background: linear-gradient(
      to bottom,
      rgba(11, 14, 30, 0.7) 0%,
      rgba(11, 14, 30, 0) 100%
    );
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10rem;
    z-index: 1;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }
`;

export default HeroWrapper;
