import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

// Components
import {
  HeroWrapper,
  HeroArrows,
  HeroImage,
  HeroName,
  HeroNumber
} from './files';

class HeroMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  next = () => {
    this.slider1.slickNext();
    this.slider2.slickNext();
    this.slider3.slickNext();
  };

  previous = () => {
    this.slider1.slickPrev();
    this.slider2.slickPrev();
    this.slider3.slickPrev();
  };

  render() {
    const slider1 = {
      ref: c => (this.slider1 = c)
    };

    const slider2 = {
      ref: c => (this.slider2 = c)
    };

    const slider3 = {
      ref: c => (this.slider3 = c)
    };

    return (
      <StaticQuery
        query={graphql`
          query {
            allContentfulProperties(sort: { fields: [menuOrder], order: ASC }) {
              edges {
                node {
                  id
                  title
                  seoContent {
                    menuTitle
                    slug
                    featuredImage {
                      title
                      fluid(maxWidth: 2650) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                      }
                    }
                  }
                  information {
                    tagline
                  }
                  menuOrder
                }
              }
            }
          }
        `}
        render={data => {
          return (
            <HeroWrapper>
              <HeroImage
                slider1={slider1}
                properties={data.allContentfulProperties.edges}
              />
              <HeroNumber
                slider2={slider2}
                properties={data.allContentfulProperties.edges}
              />
              <HeroName
                slider3={slider3}
                properties={data.allContentfulProperties.edges}
              />
              <HeroArrows next={this.next} previous={this.previous} />
            </HeroWrapper>
          );
        }}
      />
    );
  }
}
export default HeroMobile;
