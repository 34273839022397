import React from 'react';
import styled from 'styled-components';

// Icons
import { InstagramIcon } from '../../../../assets/images/icons';

const Wrapper = styled.div`
  text-align: center;
  position: relative;
  margin-top: 3rem;
  &:after {
    content: '';
    position: absolute;
    bottom: 50%;
    left: 8.333%;
    right: 8.333%;
    height: 2.5em;
    border: 1px solid ${props => props.theme.colors.global.border.light};
    border-top: 0;
    @media (max-width: ${props => props.theme.responsive.small}) {
      left: 0;
      right: 0;
    }
  }
  h5 {
    display: inline-block;
    background: ${props => props.theme.colors.global.background.dark};
    margin: 0;
    position: relative;
    z-index: 1;
    padding: 0 2em;
    @media (max-width: ${props => props.theme.responsive.small}) {
      padding: 0 1.5em;
      width: 75%;
      font-size: 1rem;
    }
    a {
      color: ${props => props.theme.colors.global.base.light};
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: ${props => props.theme.responsive.small}) {
        vertical-align: middle;
        display: inline;
      }
      span {
        transition: all 300ms ease-in-out;
      }
      &:hover {
        span {
          color: ${props => props.theme.colors.global.primary};
        }
        svg {
          path {
            fill: ${props => props.theme.colors.global.primary};
            transition: all 0.6s ease-out;
          }
        }
      }
      svg {
        margin-left: 0.5em;
        fill: ${props => props.theme.colors.global.primary};
        @media (max-width: ${props => props.theme.responsive.small}) {
          vertical-align: middle;
          display: inline;
        }
        path {
          transition: all 0.6s ease-out;
        }
      }
    }
  }
`;

const InstagramFooter = () => (
  <Wrapper>
    <h5>
      <a
        href="https://www.instagram.com/mountain_whispers/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Follow&nbsp;
        <span>@mountain_whispers</span>
        &nbsp;on Instagram
        {InstagramIcon}
      </a>
    </h5>
  </Wrapper>
);

export default InstagramFooter;
