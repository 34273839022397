import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

// Components
import Loading from '../../../common/loading';

const Wrapper = styled.div`
  .slick-slider {
    @media (max-width: ${props => props.theme.responsive.small}) {
      display: block;
      margin-left: -1.875em;
      margin-right: -1.875em;
    }
  }
  .slick-slide > div {
    display: flex;
    .insta-img {
      display: flex !important;
      align-items: center;
      padding: 0 0.5em;
      position: relative;
      margin: 0 0.25em;
      height: 12em;
      @media (max-width: ${props => props.theme.responsive.medium}) {
        height: 15em;
      }
      @media (max-width: ${props => props.theme.responsive.small}) {
        height: 18em;
      }
      &:hover {
        .insta-caption {
          opacity: 1;
        }
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .insta-caption {
        position: absolute;
        opacity: 0;
        background: ${props => props.theme.colors.global.background.overlay};
        color: ${props => props.theme.colors.global.base.light};
        text-align: center;
        padding: 20px 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        transition: all 0.3s ease-out;
        p {
          margin: 0;
          font-size: 0.75em;
          padding: 0.5em;
          width: 100%;
        }
      }
    }
  }
`;

class InstagramSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      instagramData: []
    };
  }

  componentDidMount() {
    const self = this;
    const url = `https://api.instagram.com/v1/users/self/media/recent/?access_token=202237145.1677ed0.7e74aa63fc914bc0995a3cf137881260&count=10`;
    fetch(url)
      .then(data => data.json())
      .then(data => {
        !this.isCancelled &&
          self.setState({
            instagramData: data,
            isLoading: false
          });
      });
  }

  componentWillUnmount() {
    this.isCancelled = true;
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      arrows: false,
      speed: 1500,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2,
            centerMode: true
          }
        }
      ]
    };

    const { isLoading, instagramData } = this.state;

    return (
      <Wrapper>
        {isLoading && <Loading />}
        {instagramData !== null && !isLoading && (
          <Slider {...settings}>
            {instagramData.data.map(i => {
              const id = i.id;
              const link = i.link;
              const image = i.images.standard_resolution.url;
              const text = i.caption ? i.caption.text : '';
              const caption = text.replace(/http(.*)/g, '');
              return (
                <a
                  key={id}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="insta-img"
                >
                  <img src={image} alt={caption} />
                  <div className="insta-caption">
                    <p>{caption}</p>
                  </div>
                </a>
              );
            })}
          </Slider>
        )}
      </Wrapper>
    );
  }
}
export default InstagramSlider;
