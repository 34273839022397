import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Slider from 'react-slick';

const SliderThree = styled(Slider)`
  position: absolute;
  bottom: 8rem;
  padding-bottom: 1rem;
  left: 0;
  z-index: 2;
  width: 100%;
  text-align: center;
  .slick-list {
    .slick-track {
      a {
        color: ${props => props.theme.colors.global.base.light};
        padding: 0 1rem;
        flex-shrink: 1;
        display: inline-block;
        @media (max-width: ${props => props.theme.responsive.medium}) {
          padding: 0 0.5rem;
        }
        h4 {
          margin-bottom: 0.25rem;
          @media (max-width: ${props => props.theme.responsive.small}) {
            font-size: 1.643rem;
          }
        }
        p {
          margin: 0;
          @media (max-width: ${props => props.theme.responsive.small}) {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
`;

class SliderName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const settings = {
      dots: false,
      arrows: false,
      pauseOnHover: false,
      fade: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    const { properties, slider3 } = this.props;
    return (
      <SliderThree {...settings} {...slider3}>
        {properties.map(({ node: slick }) => (
          <Link key={slick.id} to={`/property/${slick.seoContent.slug}/`}>
            <h4>{slick.seoContent.menuTitle}</h4>
            <p>{slick.information.tagline}</p>
          </Link>
        ))}
      </SliderThree>
    );
  }
}

export default SliderName;
