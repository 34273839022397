import React, { Fragment } from 'react';

// Components
import { HeroWrapper, HeroVideo, HoverImages } from './files';
import HeroMobile from '../heroMobile';

const HeroIndex = ({ contentSection }) => (
  <Fragment>
    <HeroWrapper>
      <HeroVideo contentSection={contentSection} />
      <HoverImages />
    </HeroWrapper>
    <HeroMobile />
  </Fragment>
);

export default HeroIndex;
