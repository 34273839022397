import React from 'react';
import styled from 'styled-components';

// Icons
import {
  ScrollLeftIcon,
  ScrollRightIcon
} from '../../../../../assets/images/icons';

const ArrowLeft = styled.span`
  position: absolute;
  z-index: 3;
  cursor: pointer;
  top: 80%;
  left: 1.75rem;
  padding: 0;
  transform: translateY(-20%);
  border: 0;
  min-width: 0;
  display: block;
  transition: all 0.3s ease !important;
  &:before {
    content: none !important;
  }
  svg {
    path {
      fill: ${props => props.theme.colors.global.base.light};
    }
  }
  &:hover {
    transform: translate(-4px, -20%) !important;
  }
`;

const ArrowRight = styled.span`
  position: absolute;
  z-index: 3;
  cursor: pointer;
  top: 80%;
  right: 1.75rem;
  padding: 0;
  transform: translateY(-20%);
  border: 0;
  min-width: 0;
  display: block;
  transition: all 0.3s ease !important;
  &:before {
    content: none !important;
  }
  svg {
    polygon {
      fill: ${props => props.theme.colors.global.base.light};
    }
  }
  &:hover {
    transform: translate(4px, -20%) !important;
  }
`;

class HeroArrows extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { previous, next } = this.props;

    return (
      <React.Fragment>
        <ArrowLeft type="button" onClick={previous}>
          {ScrollLeftIcon}
        </ArrowLeft>
        <ArrowRight type="button" onClick={next}>
          {ScrollRightIcon}
        </ArrowRight>
      </React.Fragment>
    );
  }
}

export default HeroArrows;
