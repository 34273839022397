import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
  position: relative;
  margin-bottom: 3rem;
  h2 {
    color: ${props => props.theme.colors.global.base.light};
    position: relative;
    background: ${props => props.theme.colors.global.background.dark};
    display: inline-block;
    z-index: 1;
    padding: 0 1.5em;
    margin: 0;
    @media (max-width: ${props => props.theme.responsive.small}) {
      padding: 0 0.5em;
      width: 75%;
      font-size: 1.75rem;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 8.333%;
    right: 8.333%;
    height: 2.5em;
    border: 1px solid ${props => props.theme.colors.global.border.light};
    border-bottom: 0;
    @media (max-width: ${props => props.theme.responsive.small}) {
      left: 0;
      right: 0;
    }
  }
`;

const InstagramHeader = () => (
  <Wrapper>
    <h2>Moments Shared</h2>
  </Wrapper>
);

export default InstagramHeader;
