import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  #background-video {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 115vh;
    object-fit: cover;
  }
  video {
    source {
      &:last-child {
        display: none;
      }
    }
  }
`;

const HeroVideo = ({ contentSection }) => (
  <Wrapper>
    <video
      poster={contentSection.imageGallery[3].fluid.srcWebp}
      id="background-video"
      playsInline
      loop
      muted
      autoPlay
    >
      {contentSection.imageGallery.map(i => (
        <source key={i.file.url} src={i.file.url} type={i.file.contentType} />
      ))}
      Your browser does not support the video tag.
    </video>
  </Wrapper>
);

export default HeroVideo;
