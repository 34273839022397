import React from 'react';

import {
  InstagramWrapper,
  InstagramHeader,
  InstagramSlider,
  InstagramFooter
} from './files';

const InstagramIndex = () => (
  <InstagramWrapper id="instagram">
    <InstagramHeader />
    <InstagramSlider />
    <InstagramFooter />
  </InstagramWrapper>
);

export default InstagramIndex;
