import styled from 'styled-components';

const HeroWrapper = styled.section`
  height: 75vh;
  max-height: 42em;
  min-height: 45em;
  padding: 0;
  position: relative;
  margin-bottom: 5em;
  overflow: hidden;
  z-index: 3;
  text-align: center;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    min-height: 32em;
  }
  @media (max-width: ${props => props.theme.responsive.small}) {
    display: none;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 25%;
    z-index: 1;
    background: linear-gradient(
      to bottom,
      rgba(11, 14, 30, 0.7) 0%,
      rgba(11, 14, 30, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#b30b0e1e',
        endColorstr='#000b0e1e',
        GradientType=0
      );
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 45%;
    z-index: 1;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }
`;

export default HeroWrapper;
