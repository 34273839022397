import React from 'react';
import styled from 'styled-components';

// Styles
import { Rotate } from '../../../assets/styles/globals/files';

const Wrapper = styled.div`
  position: relative;
  margin: 1em auto;
  height: 100px;
`;

const LoadingInner = styled.div`
  margin: -25px 0 0 -25px;
  height: 50px;
  width: 50px;
  animation: ${Rotate} 1000ms linear infinite;
  &:before {
    content: '';
    margin: -23px 0 0 -23px;
    height: 44px;
    width: 44px;
    animation: ${Rotate} 1000ms linear infinite;
  }
  &:after {
    content: '';
    margin: -29px 0 0 -29px;
    height: 56px;
    width: 56px;
    animation: ${Rotate} 2000ms linear infinite;
  }
  &.loading,
  &.loading::before,
  &.loading::after {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 1px solid rgba(231, 182, 121, 0.1);
    border-left-color: rgba(231, 182, 121, 0.4);
    border-radius: 999px;
  }
`;

const Loading = () => (
  <Wrapper>
    <LoadingInner className="loading" />
  </Wrapper>
);

export default Loading;
