import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

const SliderTwo = styled(Slider)`
  position: absolute;
  bottom: 2.4rem;
  left: 0;
  width: 100%;
  text-align: center;
  color: ${props => props.theme.colors.global.base.light};
  font-family: ${props => props.theme.fonts.header};
  z-index: 3;
  font-size: 1.125rem;
  .slash {
    opacity: 0.4;
    margin: 0 0.5em;
  }
`;

class SliderNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const settings = {
      dots: false,
      arrows: false,
      pauseOnHover: false,
      fade: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    const { properties, slider2 } = this.props;
    const totalCount = properties.length;

    return (
      <SliderTwo {...settings} {...slider2}>
        {properties.map((node, index) => {
          const currentNumber = index + 1;
          return (
            <span key={currentNumber}>
              {currentNumber}
              <span className="slash">/</span>
              {totalCount}
            </span>
          );
        })}
      </SliderTwo>
    );
  }
}

export default SliderNumber;
