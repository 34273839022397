import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

// Icons
import { ArrowRightIcon } from '../../../../../assets/images/icons';

const Wrapper = styled.div`
  position: absolute;
  bottom: 6em;
  left: 0;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  a {
    color: ${props => props.theme.colors.global.base.light};
    padding: 0 1rem;
    flex-shrink: 1;
    display: inline-block;
    @media (max-width: ${props => props.theme.responsive.medium}) {
      padding: 0 0.75rem;
    }
    &.fadeProperty {
      opacity: 0.3;
    }
    &:hover {
      h4,
      p {
        color: ${props => props.theme.colors.global.base.light};
        svg {
          transform: translateX(0.25rem);
        }
      }
    }
    h4 {
      margin-bottom: 0.25rem;
      svg {
        width: 0.75rem;
        height: 0.75rem;
        margin-left: 0.75rem;
        transition: all 0.3s ease-out;
        @media (max-width: 64em) {
          display: none;
        }
        polygon {
          fill: ${props => props.theme.colors.global.base.light};
        }
      }
    }
    p {
      font-weight: 400;
      margin: 0;
      opacity: 0.5;
      font-size: 0.86em;
    }
  }
`;

const PropertyLinks = ({
  properties,
  selectedID,
  hover,
  hoverOn,
  hoverOff
}) => (
  <Wrapper>
    {properties.map(({ node: property }) => {
      const isNotActive = property.id !== selectedID;
      const fadeProperty = hover === true && isNotActive ? 'fadeProperty' : '';
      return (
        <Link
          key={property.id}
          to={`/property/${property.seoContent.slug}/`}
          className={fadeProperty}
          onMouseEnter={() => hoverOn(property.id)}
          onMouseLeave={() => hoverOff()}
        >
          <h4>
            {property.seoContent.menuTitle}
            {ArrowRightIcon}
          </h4>
          <p>{property.information.tagline}</p>
        </Link>
      );
    })}
  </Wrapper>
);

export default PropertyLinks;
