import styled from 'styled-components';

const InstagramWrapper = styled.div`
  background: ${props => props.theme.colors.global.background.dark};
  padding-left: 2.5em;
  padding-right: 2.5em;
  max-width: 83.75em;
  margin: 5em auto;
  position: relative;
  @media (max-width: ${props => props.theme.responsive.small}) {
    margin: 3em auto;
    padding-left: 1.875em;
    padding-right: 1.875em;
  }
`;

export default InstagramWrapper;
